export default class EnumMetadata {
  constructor() {
    this.titles = {
      "TaskPriorityEnum": {},
      "MultiYearPlanPriority": {},
      "RelationshipType": {},
      "TaskRequestStatus": {},
      "TaskStatus": {},
      "Status": {},
      "OriginModuleEnum": {},
      "OwnershipMode": {},
      "DocumentStatus": {},
      "ProjectStatus": {},
      "HazardousSubstanceWGK": {},
      "SignatoryType": {},
      "RecommendationStatus": {}
    }
    this.classNames = {
      "TaskPriorityEnum": {
        "Low": "priority-low",
        "Normal": "priority-normal",
        "High": "priority-high",
      },
      "MultiYearPlanPriority": {
        "Low": "priority-low",
        "Normal": "priority-normal",
        "High": "priority-high",
      },
      "Status": {
        "OutOfService": "status-error",
        "Sick": "status-warning",
        "InTreatment": "status-warning",
        "InService": "status-completed",
        "ToCommission1": "status-active",
        "ToCommission2": "status-active",
        "Active": "status-active",
        "ActiveShort": "status-active",
        "ToCreate": "status-warning",
        "NotSigned": "status-active",
        "Inactive": "status-error",
        "Denied": "status-error",
        "MultiYearPlan": "status-completed",

        "Pending": "status-warning",
        "Refused": "status-error",
        "Accepted": "status-active",
        "OnHold": "status-warning",
        "InProcess": "status-warning",
        "ToBePlanned": "status-warning",
        "ToPlan": "status-warning",
        "Planned": "status-active",
        "PlanningOverdue": "status-error",
        "ToBeCarriedOut": "status-active",
        "CarriedOut": "status-active",
        "ExecutionStarted": "status-active",
        "ExecutionOverdue": "status-error",
        "ToVerify": "status-active",
        "ToBeCompleted": "status-active",
        "Completed": "status-completed",

        "Overdue": "status-error",
        "Closed": "status-closed",

        "Expired": "status-error",
        "OverTime": "status-error",

        "ToExpire": "status-warning",
        "Cancelled": "status-warning",

        "ToSign": "status-active",
      },
      "OriginModuleEnum": {
        "PeriodicMaintenance": "far fa-tools",
        "PeriodicControl": "far fa-check-square",
        "Project": "fas fa-tasks-alt",
        "Task": "far fa-tasks",
        "NotificationDefect": "far fa-bell-exclamation",
        "ActivityEntry": "fal fa-file-signature",

        "Building": "far fa-building",
        "Room": "far fa-door-open",
        "Labourmeans": "far fa-wrench",
        "PBM": "far fa-headphones",
        "EquipmentInstallation": "far fa-charging-station",
        "IntangibleAsset": "fas fa-hand-holding-box",
        "HazardousSubstance": "far fa-flask",
        "CombinedInstallation": "far fa-link",

        "Commissioning": "far fa-sign-in",
        "SafetyInstructionCard": "far fa-file-alt",
        "OutOfCommissioning": "far fa-sign-out",
        "Consultancy": "far fa-comments-alt",
        "RiskAnalysis": "far fa-retweet",
        "IncidentManagement": "far fa-exclamation-triangle",
        "GlobalPreventionPlan": "far fa-globe",
        "YearActionPlan": "far fa-line-columns",
        "Training": "far fa-book-reader",

        "Document": "fas fa-books",

        "Employee": "far fa-users",
        "Supplier": "far fa-truck",
        "Contact": "fas fa-address-book",

        "SchoolGroup": "far fa-sitemap",
        "School": "far fa-school",

        "UserRole": "far fa-user-tag",

        "Tag": "far fa-tag",

        "Trust": "far fa-tag",

        "Planning": "far fa-calendar",

        "SupportTicket": "far fa-headset",
        "Report": "far fa-clipboard-list",
        "Student": "far fa-user",

        "Form": "fab fa-wpforms",

        "Periodical": "fas fa-repeat",
        "InstructionCard": "far fa-file-alt"
      },
      "RelationshipType": {
        "Responsible": "far fa-users",
        "CoResponsible": "far fa-users",
        "Cc": "far fa-users",
        "Assignee": "far fa-users",
        "Creator": "far fa-users",
        "Owner": "far fa-users",
        "PreventionAdvisorSignatory": "fas fa-signature",
        "HierarchicalLineSignatory": "fas fa-signature",
        "OtherSignatory": "fas fa-signature",
      }
    };
    this.icons = {
      "PeriodicMaintenance": "tools",
      "PeriodicControl": "check-square",
      "Project": "tasks-alt",
      "Task": "tasks",
      "NotificationDefect": "bell-exclamation",
      "ActivityEntry": "file-signature",

      "Building": "building",
      "Room": "door-open",
      "Labourmeans": "wrench",
      "PBM": "headphones",
      "EquipmentInstallation": "charging-station",
      "IntangibleAsset": "hand-holding-box",
      "HazardousSubstance": "flask",
      "CombinedInstallation": "link",

      "Commissioning": "sign-in",
      "SafetyInstructionCard": "file-alt",
      "OutOfCommissioning": "sign-out",
      "Consultancy": "comments-alt",
      "RiskAnalysis": "retweet",
      "IncidentManagement": "exclamation-triangle",
      "GlobalPreventionPlan": "globe",
      "YearActionPlan": "line-columns",
      "Training": "book-reader",

      "Document": "archive",

      "Employee": "users",
      "Supplier": "truck",
      "Contact": "address-book",

      "SchoolGroup": "sitemap",
      "School": "school",

      "UserRole": "user-tag",

      "Tag": "tag",

      "Trust": "tag",

      "Planning": "calendar",

      "Form": "wpforms",

      "Periodical": "repeat",
      "InstructionCard": "file-alt",
    };
  }

  setTitles(t) {
    this.titles = {
      "TaskPriorityEnum": {
        "Low": t("enums.TaskPriorityEnum.Low"),
        "Normal": t("enums.TaskPriorityEnum.Normal"),
        "High": t("enums.TaskPriorityEnum.High"),
      },
      "MultiYearPlanPriority": {
        "Low": t("enums.MultiYearPlanPriority.Low"),
        "Normal": t("enums.MultiYearPlanPriority.Normal"),
        "High": t("enums.MultiYearPlanPriority.High"),
      },
      "RelationshipType": {
        "Responsible": t("enums.RelationshipType.Responsible"),
        "CoResponsible": t("enums.RelationshipType.CoResponsible"),
        "Cc": t("enums.RelationshipType.Cc"),
        "Assignee": t("enums.RelationshipType.Assignee"),
        "PreventionAdvisor": t("enums.RelationshipType.PreventionAdvisor"),
        "PreventionAdvisorSignatory": t("enums.RelationshipType.PreventionAdvisorSignatory"),
        "HierarchicalLineSignatory": t("enums.RelationshipType.HierarchicalLineSignatory"),
        "OtherSignatory": t("enums.RelationshipType.OtherSignatory")
      },
      "TaskRequestStatus": {
        "Pending": t("enums.TaskRequestStatus.Pending"),
        "Accepted": t("enums.TaskRequestStatus.Accepted"),
        "Refused": t("enums.TaskRequestStatus.Refused"),
        "Completed": t("enums.TaskRequestStatus.Completed"),
      },
      "TaskStatus": {
        "InProcess": t("enums.TaskStatus.InProcess"),
        "OnHold": t("enums.TaskStatus.OnHold"),
        "Active": t("enums.TaskStatus.Active"),
        "Completed": t("enums.TaskStatus.Completed"),
        "ToVerify": t("enums.TaskStatus.ToVerify"),
        "ExecutionOverdue": t("enums.TaskStatus.ExecutionOverdue"),
        "MultiYearPlan": t("enums.TaskStatus.MultiYearPlan"),
      },
      "ProjectStatus": {
        "InProcess": t("enums.ProjectStatus.InProcess"),
        "OnHold": t("enums.ProjectStatus.OnHold"),
        "Active": t("enums.ProjectStatus.Active"),
        "Completed": t("enums.ProjectStatus.Completed"),
        "Cancelled": t("enums.ProjectStatus.Cancelled")
      },
      "IncidentV2Status": {
        "Pending": t("enums.IncidentStatus.Pending"),
        "ToInvestigate": t("enums.IncidentStatus.ToInvestigate"),
        "Completed": t("enums.IncidentStatus.Completed"),
      },
      "DocumentStatus": {
        "InProcess": t("statuses.InProcess"),
        "Active": t("statuses.Active"),
        "Expired": t("statuses.Expired"),
        "ToExpire": t("statuses.ToExpire")
      },
      "PeriodicalStatus": {
        "InProcess": t("statuses.InProcess"),
        "Active": t("statuses.Active"),
        "OnHold": t("statuses.OnHold"),
        "Completed": t("statuses.Completed")
      },
      "FormStatus": {
        "InProcess": t("statuses.InProcess"),
        "Active": t("statuses.Active"),
        "Expired": t("statuses.Expired"),
        "ToExpire": t("statuses.ToExpire")
      },
      "RecommendationStatus": {
        "InProcess": t("statuses.InProcess"),
        "Requested": t("statuses.Requested"),
        "Refused": t("statuses.Refused"),
        "InTreatment": t("statuses.InTreatment"),
        "ToSign": t("statuses.ToSign"),
        "Active": t("statuses.Active"),
      },
      "Status": {
        "OutOfService": t("statuses.OutOfService"),
        "Sick": t("statuses.Sick"),
        "InTreatment": t("statuses.InTreatment"),
        "InService": t("statuses.InService"),
        "ToCommission1": t("statuses.ToCommission1"),
        "ToCommission2": t("statuses.ToCommission2"),
        "Active": t("statuses.Active"),
        "ActiveShort": t("statuses.ActiveShort"),
        "ToCreate": t("statuses.ToCreate"),
        "NotSigned": t("statuses.NotSigned"),
        "Inactive": t("statuses.Inactive"),
        "Denied": t("statuses.Denied"),
        "MultiYearPlan": t("statuses.MultiYearPlan"),
        "InProcess": t("statuses.InProcess"),
        "ToBePlanned": t("statuses.ToBePlanned"),
        "ToPlan": t("statuses.ToPlan"),
        "Planned": t("statuses.Planned"),
        "PlanningOverdue": t("statuses.PlanningOverdue"),
        "ToBeCarriedOut": t("statuses.ToBeCarriedOut"),
        "CarriedOut": t("statuses.CarriedOut"),
        "ExecutionStarted": t("statuses.Active"),
        "ExecutionOverdue": t("statuses.ExecutionOverdue"),
        "ToVerify": t("statuses.ToVerify"),
        "ToBeCompleted": t("statuses.ToBeCompleted"),
        "Completed": t("statuses.Completed"),
        "Overdue": t("statuses.Overdue"),
        "Closed": t("statuses.Closed"),
        "Expired": t("statuses.Expired"),
        "OverTime": t("statuses.OverTime"),
        "Pending": t("statuses.Pending"),
        "Accepted": t("statuses.Accepted"),
        "Refused": t("statuses.Refused"),
        "OnHold": t("statuses.OnHold"),
        "ToInvestigate": t("statuses.ToInvestigate"),
        "ToExpire": t("statuses.ToExpire"),
        "Cancelled": t("statuses.Cancelled"),
        "ToSign": t("statuses.ToSign"),
        "Signed": t("statuses.Signed"),
        "Requested": t("statuses.Requested")
      },
      "OriginModuleEnum": {
        "Task": t("modules.task"),
        "NotificationDefect": t("modules.taskrequest"),
        "PeriodicMaintenance": t("modules.maintenance"),
        "PeriodicControl": t("modules.inspection"),
        "ActivityEntry": t("modules.activityentry"),

        "Building": t("modules.building"),
        "Room": t("modules.location"),
        "Labourmeans": t("modules.labourmeans"),
        "PBM": t("modules.protection_equipment"),
        "HazardousSubstance": t("modules.hazardous_substance"),
        "CombinedInstallation": t("modules.combined_installation"),
        "IntangibleAsset": t("modules.intangible_asset"),

        "Commissioning": t("modules.commissioning"),
        "SafetyInstructionCard": t("modules.safetyinstructioncard"),
        "OutOfCommissioning": t("modules.decommissioning"),
        "Consultancy": t("modules.consultancy"),
        "RiskAnalysis": t("modules.riskanalysis"),
        "IncidentManagement": t("modules.incidentmanagement"),
        "Training": t("modules.training"),

        "Document": t("modules.documentmanagement"),
        "EquipmentInstallation": t("modules.equipmentinstallation"),

        "GlobalPreventionPlan": t("modules.multiyearplan"),
        "Supplier": t("modules.supplier_customer"),
        "Employee": t("modules.employee"),
        "Student": t("modules.student"),

        "Contact": t("modules.contact"),

        "SchoolGroup": t("modules.legalstructure"),
        "School": t("modules.branch"),

        "UserRole": t("modules.userrole"),
        "Tag": t("modules.tag"),

        "Trust": t("modules.trust"),
        "Planning": t("modules.planning"),
        "Project": t("modules.project"),

        "SupportTicket": t("modules.support_ticket"),

        "Report": t("modules.report"),

        "Form": t("modules.form"),

        "Periodical": t("modules.periodical"),
        "InstructionCard": t("modules.instructioncard")

      },
      "Sex": {
        "Female": t("enums.Sex.Female"),
        "Male": t("enums.Sex.Male")
      },
      "IncidentManagementLocationType": {
        "Internal": t("enums.IncidentManagementLocationType.Internal"),
        "External": t("enums.IncidentManagementLocationType.External")
      },
      "IncidentManagementVictimType": {
        "None": t("enums.IncidentManagementVictimType.None"),
        "Employee": t("enums.IncidentManagementVictimType.Employee"),
        "Student": t("enums.IncidentManagementVictimType.Student"),
        "ThirdParty": t("enums.IncidentManagementVictimType.ThirdParty")
      },
      "IncidentManagementCareType": {
        "EHBOContact": t("enums.IncidentManagementCareType.EHBOContact"),
        "Accident": t("enums.IncidentManagementCareType.Accident")
      },
      "RecurrencePattern": {
        "None": t("enums.RecurrencePattern.None"),
        "Daily": t("enums.RecurrencePattern.Daily"),
        "Weekly": t("enums.RecurrencePattern.Weekly"),
        "Monthly": t("enums.RecurrencePattern.Monthly"),
        "Yearly": t("enums.RecurrencePattern.Yearly"),
      },
      "WeekOfMonth": {
        "First": t("enums.WeekOfMonth.First"),
        "Second": t("enums.WeekOfMonth.Second"),
        "Third": t("enums.WeekOfMonth.Third"),
        "Fourth": t("enums.WeekOfMonth.Fourth"),
        "Last": t("enums.WeekOfMonth.Last"),
      },
      "DayOfWeek": {
        "Monday": t("enums.DayOfWeek.Monday"),
        "Tuesday": t("enums.DayOfWeek.Tuesday"),
        "Wednesday": t("enums.DayOfWeek.Wednesday"),
        "Thursday": t("enums.DayOfWeek.Thursday"),
        "Friday": t("enums.DayOfWeek.Friday"),
        "Saturday": t("enums.DayOfWeek.Saturday"),
        "Sunday": t("enums.DayOfWeek.Sunday"),
      },
      "DayOfWeekFlag": {
        "1": t("enums.DayOfWeekFlag.Monday"),
        "2": t("enums.DayOfWeekFlag.Tuesday"),
        "4": t("enums.DayOfWeekFlag.Wednesday"),
        "8": t("enums.DayOfWeekFlag.Thursday"),
        "16": t("enums.DayOfWeekFlag.Friday"),
        "32": t("enums.DayOfWeekFlag.Saturday"),
        "64": t("enums.DayOfWeekFlag.Sunday"),
      },
      "MonthOfYear": {
        "January": t("enums.MonthOfYear.January"),
        "February": t("enums.MonthOfYear.February"),
        "March": t("enums.MonthOfYear.March"),
        "April": t("enums.MonthOfYear.April"),
        "May": t("enums.MonthOfYear.May"),
        "June": t("enums.MonthOfYear.June"),
        "July": t("enums.MonthOfYear.July"),
        "August": t("enums.MonthOfYear.August"),
        "September": t("enums.MonthOfYear.September"),
        "October": t("enums.MonthOfYear.October"),
        "November": t("enums.MonthOfYear.November"),
        "December": t("enums.MonthOfYear.December"),
      },
      "MonthOfYearFlag": {
        "January": t("enums.MonthOfYearFlag.January"),
        "February": t("enums.MonthOfYearFlag.February"),
        "March": t("enums.MonthOfYearFlag.March"),
        "April": t("enums.MonthOfYearFlag.April"),
        "May": t("enums.MonthOfYearFlag.May"),
        "June": t("enums.MonthOfYearFlag.June"),
        "July": t("enums.MonthOfYearFlag.July"),
        "August": t("enums.MonthOfYearFlag.August"),
        "September": t("enums.MonthOfYearFlag.September"),
        "October": t("enums.MonthOfYearFlag.October"),
        "November": t("enums.MonthOfYearFlag.November"),
        "December": t("enums.MonthOfYearFlag.December"),
      },
      "NotificationPattern": {
        "Day": t("enums.NotificationPattern.Day"),
        "Week": t("enums.NotificationPattern.Week"),
        "Month": t("enums.NotificationPattern.Month"),
      },
      "ObjectDocumentType": {
        "Image": t("document_types.image"),
        "Manual": t("document_types.manual"),
        "Certificate": t("document_types.certificate"),
        "AdditionalDocument": t("document_types.additional_document"),
        "FloorPlan": t("document_types.floorplan"),
        "LandRegister": t("document_types.landregister"),
        "WorkRules": t("document_types.workrules"),
        "EvacuationPlan": t("document_types.evacuationplan"),
        "PartitioningPlan": t("document_types.partitioningplan"),
        "Contract": t("document_types.contract"),
        "Maindocument": t("document_types.maindocument"),
        "Sds": t("document_types.sds"),
        "ChemistryCard": t("document_types.chemistry_card"),
        "FormImage": t("document_types.formimage"),
        "FormDocument": t("document_types.formdocument"),
        "ActivityDocument": t("document_types.activitydocument"),
        "ActivityImage": t("document_types.activityimage"),
      },
      "ReportMainGrouping": {
        "Module": t("report_main_groups.module"),
        "Scope": t("report_main_groups.scope"),
        "ScopeThenModule": t("report_main_groups.scopeThenModule"),
        "ModuleThenScope": t("report_main_groups.moduleThenScope")
      },
      "HazardousSubstanceWGK": {
        "WGK0": t("hazardous_substance_wgk.wgk0"),
        "WGK1": t("hazardous_substance_wgk.wgk1"),
        "WGK2": t("hazardous_substance_wgk.wgk2"),
        "WGK3": t("hazardous_substance_wgk.wgk3"),
      },
      "HazardousSubstanceSignalWord": {
        "NotApplicable": t("hazardous_substance_signal_word.notapplicable"),
        "Warning": t("hazardous_substance_signal_word.warning"),
        "Danger": t("hazardous_substance_signal_word.danger"),
      },
      "TaskType": {
        "Internal": t("enums.TaskType.Internal"),
        "External": t("enums.TaskType.External"),
      },
      "ConsultancyTypeEnum": {
        "Formal": t("enums.ConsultancyTypeEnum.Formal"),
        "General": t("enums.ConsultancyTypeEnum.General"),
        "Purchase": t("enums.ConsultancyTypeEnum.Purchase"),
        "GeneralOrPurchase": t("enums.ConsultancyTypeEnum.GeneralOrPurchase"),
      },
      "SignatoryType": {
        "PreventionAdvisor": t("enums.SignatoryType.PreventionAdvisor"),
        "Other": t("enums.SignatoryType.Other"),
        "HierarchicalLine": t("enums.SignatoryType.HierarchicalLine")
      },
      "FavorableEnum": {
        "FAVORABLE": t("enums.FavorableEnum.FAVORABLE"),
        "UNFAVORABLE": t("enums.FavorableEnum.UNFAVORABLE")
      }
    };
  }

  getTitle = (type, value) => {
    const map = this.titles[type];
    return map && map[value];
  }

  getClassName = (type, value) => {
    const map = this.classNames[type];
    return map && map[value];
  }
}
