/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ObjectDocumentType {
    None = 'None',
    AdditionalDocument = 'AdditionalDocument',
    Manual = 'Manual',
    Certificate = 'Certificate',
    MainDocument = 'MainDocument',
    EvacuationPlan = 'EvacuationPlan',
    FloorPlan = 'FloorPlan',
    PartitioningPlan = 'PartitioningPlan',
    Sds = 'SDS',
    ChemistryCard = 'ChemistryCard',
    ParticipantList = 'ParticipantList',
    Syllabus = 'Syllabus',
    PreAdvice = 'PreAdvice',
    FormalAdvice = 'FormalAdvice',
    GeneralAdvice = 'GeneralAdvice',
    Quote = 'Quote',
    Order = 'Order',
    RiskAnalysis = 'RiskAnalysis',
    Controle = 'Controle',
    Maintenance = 'Maintenance',
    Procedure = 'Procedure',
    HistoricReport = 'HistoricReport',
    DeclarationForm = 'DeclarationForm',
    WitnessDeclaration = 'WitnessDeclaration',
    Image = 'Image',
    LandRegister = 'LandRegister',
    SchoolRules = 'SchoolRules',
    WorkRules = 'WorkRules',
    IncidentInvestigation = 'IncidentInvestigation',
    SafetyInstructionCard = 'SafetyInstructionCard',
    InsuranceDocument = 'InsuranceDocument',
    PostFiche = 'PostFiche',
    Contract = 'Contract',
    MailAttachment = 'MailAttachment',
    FormDocument = 'FormDocument',
    FormImage = 'FormImage',
    ActivityDocument = 'ActivityDocument',
    ActivityImage = 'ActivityImage'
}

export function ObjectDocumentTypeFromJSON(json: any): ObjectDocumentType {
    return ObjectDocumentTypeFromJSONTyped(json, false);
}

export function ObjectDocumentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ObjectDocumentType {
    return json as ObjectDocumentType;
}

export function ObjectDocumentTypeToJSON(value?: ObjectDocumentType | null): any {
    return value as any;
}

