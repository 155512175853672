import React, { Component, Fragment } from "react";
import arxs from "infra/arxs";
import GlobalContext from "infra/GlobalContext";
import { ActivityType, ObjectDocumentType } from "infra/api/contracts";

import { HorizontalSeparator } from "components/shell/HorizontalSeparator";
import { TextArea } from "components/controls/TextArea";
import { createMailPopup } from "components/mailing/MailPopup";
import Avatar from "components/controls/images/Avatar";
import MentionPopup from "components/controls/MentionPopup";
import Switch from "components/controls/Switch";
import { createUpload } from "components/controls/upload/Upload";
import Toaster from "components/util/Toaster";
import DocumentLabel from 'components/controls/documents/DocumentLabel';
import { Image } from "components/controls/images/Image";
import { createImagePreview } from "components/controls/images/ImagePreview";

import "./Activities.scss";

const _fileTypes = {
  "images/pdf.jpg": [".pdf"],
  "images/doc.png": [".doc", ".dot", ".docx", ".docm", ".dotx", ".docb"],
  "images/xls.png": [".xls", ".xlsx", ".xlsm", ".xltm", ".xltx"],
  "images/ppt.png": [
    ".ppt",
    ".pptx",
    ".pot",
    ".potx",
    ".potm",
    ".pps",
    ".ppsx",
    ".ppsm",
    ".pptm",
  ],
};

export class Activities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      comment: "",
      showMentionPopup: false,
      selectionStart: 0,
      mentionRef: React.createRef(),
      securityContext: null,
    };

    this.subscription = { update: () => { }, dispose: () => { } };
  }

  componentDidMount() {
    this.reload({});
  }

  componentDidUpdate(prevProps) {
    this.reload(prevProps);
  }

  componentWillUnmount() {
    this.subscription.dispose();
  }

  reload = (prevProps) => {
    if (prevProps.objectId !== this.props.objectId && this.props.objectId) {
      this.subscription.dispose();
      this.subscription = arxs.Api.subscribeToEndpoint(
        `/api/shared/activity/${this.props.module}/${this.props.objectId}`,
        (x) =>
          this.setState({
            items: x.filter(y => !y.isDeleted).orderByDescending((x) => new Date(x.createdAt).getTime()),
          })
      );

      let securityContext;
      if (this.props.module && this.props.objectId) {
        const subject =
          arxs.Api.lookups.resolveSubject({
            module: this.props.module,
            id: this.props.objectId,
          }) || {};
        if (subject.legalStructure) {
          securityContext = arxs.securityContext.buildForContext(
            null,
            subject.legalStructure,
            subject.branch || {}
          );
        }
      }
      if (!securityContext) {
        securityContext = arxs.securityContext.buildForUserContext();
      }

      this.setState({ items: [], securityContext });
    }
  };

  comment = () => {
    if (this.state.comment) {
      arxs.ApiClient.shared.activities.comment(
        this.props.objectId,
        this.props.module,
        this.state.comment
      );
      this.setState({ comment: "" });
    }
  };

  remove(activity) {
    const newItems = this.state.items.filter(x => x.id !== activity.id);
    this.setState({ items: newItems }, () => arxs.ApiClient.shared.activities.removeComment(
      this.props.objectId,
      activity.id
    ))
  }

  upload = (context) => {
    const { objectId, module } = this.props;
    const onSubmit = (files) => {
      context.popup.close();

      const fileInfos = ((files || []).documents || [])
        .map((file) => {
          return {
            url: file.url,
            name: file.name,
            type: file.type,
            contentType: file.contentType,
            hash: file.hash,
          };
        });

      arxs.ApiClient.shared.activities
        .fileComment({ objectId: objectId, objectModule: module, files: fileInfos })
        .then((x) =>
          Toaster.success(arxs.t("kanban.actions.attachments_added_success")));

    }

    let upload = createUpload(this.props.module, null, onSubmit, null, null, [ObjectDocumentType.ActivityDocument, ObjectDocumentType.ActivityImage], null, true);
    this.setState({ upload }, () => context.popup.show(this.state.upload));
  }

  handleSubscriptionChange = () => {
    if (this.props.isSubscribed) {
      arxs.ApiClient.shared.subscriptions
        .unsubscribe(this.props.module, this.props.objectId)
        .then((x) => this.props.onChange(false));
    } else {
      arxs.ApiClient.shared.subscriptions
        .subscribe(this.props.module, this.props.objectId)
        .then((x) => this.props.onChange(true));
    }
  };

  toggleMention = () => {
    this.setState({ showMentionPopup: !this.state.showMentionPopup });
  };

  setMention = (newComment) => {
    this.setState({ comment: newComment });
  };

  onMentionClose = () => {
    this.setState({ showMentionPopup: false });
  };

  onKeyDown = (event) => {
    if (![38, 40].contains(event.keyCode)) {
      this.setState({ selectionStart: event.target.selectionStart });
    }

    this.mentionPopup.onKeyDown(event);
  };

  openMailPopup = (context, mailId) => {
    context.popup.show(
      createMailPopup({
        id: this.props.objectId,
        module: this.props.module,
        mailId: mailId,
        onClose: () => {
          context.popup.close();
        },
        securityContext: arxs.securityContext.buildForUserContext(),
      })
    );
  };

  render() {
    const isDeletable = (activity) => {
      return (
        ["Comment", "StoredFile"].indexOf(activity.type) > -1 &&
        activity.employeeId === arxs.Identity.profile.id
      );
    };
    const field = {
      getter: () => this.state.comment,
      setter: (value) => {
        this.setState({ comment: value });
      },
      id: "comment",
      name: "comment",
      multiLine: true,
    };
    const getBody = (context, item) => {
      let body = item.description;
      if (item.type === ActivityType.StoredFile) {
        if (item.isImage) {
          const imageUrl = arxs.ImageProxy.resizeImage(item.previewUrl, 140, 140);
          body = <Image
            src={imageUrl}
            alt={item.Description}
            className="preview"
            onClick={() => context.popup.show(createImagePreview([{ previewUrl: item.previewUrl }, ...this.state.items.filter(x => x.isImage && x.previewUrl !== item.previewUrl).map(x => ({ previewUrl: x.previewUrl }))]))}
          ></Image>
        } else {
          body = <div className="document"><DocumentLabel
            id={item.id}
            contentType={item.contentType}
            documentType={DocumentType.ActivityDocument}
            name={item.description}
            readOnly
            previewUrl={item.previewUrl}
            module={this.props.module}
            objectId={this.props.objectId}
            disableInlineEdit
          ></DocumentLabel>
          </div>
        }
      } else if (
        item.type === ActivityType.EmailSent ||
        item.type === ActivityType.EmailReceived
      ) {
        const message =
          item.type === ActivityType.EmailSent
            ? arxs.t("activities.sent_mail")
            : arxs.t("activities.received_mail");
        return (
          <div
            className="activity-content activity-link"
            onClick={() => this.openMailPopup(context, body)}
          >
            <i className="far fa-envelope"></i> {message}
          </div>
        );
      }
      return <div className="activity-content">{body}</div>;
    };
    const getItems = (context) =>
      this.state.items.map((activity, i) => (
        <div className="activity" key={`activity-${i}`}>
          <Avatar
            src={activity.employeeImageUrl}
            fullName={activity.employeeName}
          />
          <div className="activity-body">
            <div className="activity-author">
              <div className="activity-author-name">{activity.employeeName}</div>
              <label title={arxs.dateTime.formatDateTime(activity.createdAt)}>- {arxs.dateTime.timeAgo(activity.createdAt)}</label>
              {isDeletable(activity) && (
                <div
                  className="activity-remove-comment"
                  onClick={() => this.remove(activity)}
                >
                  <i className="far fa-trash-alt"></i>
                </div>
              )}
            </div>
            {getBody(context, activity)}
          </div>
        </div>
      ));

    const showSubscriptionToggle = this.props.showSubscriptionToggle;

    const inputLocationAtBottom = this.props.inputLocationAtBottom;

    const header = (context) => {
      return (
        <div
          className="activities-header"
          onKeyDown={this.onKeyDownSuggestionBox}
        >
          <TextArea
            field={field}
            onKeyDown={this.onKeyDown}
            innerRef={this.state.mentionRef}
          />
          <div className="activities-header-toolbar">
            <div onClick={() => this.upload(context)} className="activities-header-upload">
              <i className="far fa-paperclip"></i>
            </div>
            <div
              onClick={this.toggleMention}
              className="activities-header-mention"
            >
              <i className="far fa-at"></i>
            </div>
            <button onClick={this.comment}>
              <i className="fas fa-arrow-alt-right"></i>
            </button>
          </div>
        </div>
      );
    }

    return (
      <GlobalContext.Consumer>
        {(context) => (
          <Fragment>
            <div className="activities">
              {showSubscriptionToggle && (
                <div className="activities-follow">
                  {arxs.t("activities.title")}
                  <Switch
                    checked={!!this.props.isSubscribed}
                    onChange={this.handleSubscriptionChange}
                  />
                </div>
              )}
              {!inputLocationAtBottom && header(context)}
              <HorizontalSeparator />
              <div className="activities-body">{getItems(context)}</div>
              {inputLocationAtBottom && header(context)}
            </div>
            <MentionPopup
              text={this.state.comment}
              onSelect={(newComment) => this.setMention(newComment)}
              show={this.state.showMentionPopup}
              selectionStart={this.state.selectionStart}
              onClose={this.onMentionClose}
              textAreaRef={this.state.mentionRef}
              ref={(instance) => {
                this.mentionPopup = instance;
              }}
              securityContext={this.state.securityContext}
            />
          </Fragment>
        )}
      </GlobalContext.Consumer>
    );
  }
}
